import * as React from "react";
import { FunctionComponent, useState, useCallback } from "react";
import Layout from "../components/Base/Layout";
import { navigate } from "gatsby";
import * as styles from "./privacy-policy.module.css";
import { Link } from "@chakra-ui/react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const PrivacyPolicy: FunctionComponent = () => {
  const [isNavigationMobileOpen, setNavigationMobileOpen] = useState(false);

  const onButton1Click = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onButton2Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='contactSectionContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  return (
    <>
    <Layout>
      <div className={styles.privacyPolicy}>
        <div className={styles.docTemplate}>
          <div className={styles.textColumn}>
            <div className={styles.subtitleLarge}>
              <b className={styles.display}>TCP SUPPLY PRIVACY POLICY</b>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <span>This privacy notice for TCP Supply ("</span>
                  <b>Company</b>
                  <span>," "</span>
                  <b>we</b>
                  <span>," "</span>
                  <b>us</b>
                  <span>," or "</span>
                  <b>our</b>
                  <span>
                    "), describes how and why we might collect, store, use,
                    and/or share ("
                  </span>
                  <b>process</b>
                  <span>
                    ") your information when you use our services ("<b>Services</b>"),
                    such as when you:
                  </span>
                </div>
              </div>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.visitOurWebsite}>
                      Visit our website at <Link href="/" color="#267F88">tcpsupply.net</Link>, or any website of ours
                      that links to this privacy notice
                    </li>
                    <li>
                      Engage with us in other related ways, including any sales,
                      marketing, or events
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.visitOurWebsite}>
                    <b>Questions or concerns?</b>
                  </p>
                  <p className={styles.blankLine}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.readingThisPrivacyNoticeWi}>
                    <span>
                      Reading this privacy notice will help you understand your
                      privacy rights and choices. If you do not agree with our
                      policies and practices, please do not use our Services. If
                      you still have any questions or concerns, please contact
                      us at <Link href="mailto: info@tcpsupply.net" color="#267F88">info@tcpsupply.net</Link>.
                    </span>
                  </p>
                </div>
              </div>
              {/* <div className={styles.p1}>
                <div className={styles.caption}>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>First and last name</li>
                    <li className={styles.firstAndLast}>Email address</li>
                    <li className={styles.firstAndLast}>Phone number</li>
                    <li>
                      Address (Street address, state, city, province, ZIP/Postal
                      code)
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>SUMMARY OF KEY POINTS</div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <b className={styles.caption}>
                  This summary provides key points from our privacy notice, but
                  you can find out more details about any of these topics by
                  clicking the link following each key point or by using our
                  table of contents below to find the section you are looking
                  for.
                </b>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.visitOurWebsite}>
                    <b>What personal information do we process?</b>
                  </p>
                  <p className={styles.blankLine1}>
                    <b>&nbsp;</b>
                  </p>
                  <p className={styles.readingThisPrivacyNoticeWi}>
                    <span>
                      When you visit, use, or navigate our Services, we may
                      process personal information depending on how you interact
                      with TCP Supply and the Services, the choices you make,
                      and the products and features you use.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.visitOurWebsite}>
                    <b>Do we process any sensitive personal information?</b>
                  </p>
                  <p className={styles.visitOurWebsite}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.readingThisPrivacyNoticeWi}>
                    <span>
                      We may process sensitive personal information when
                      necessary with your consent or as otherwise permitted by
                      applicable law.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.visitOurWebsite}>
                    <b>Do we receive any information from third parties?</b>
                  </p>
                  <p className={styles.visitOurWebsite}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.readingThisPrivacyNoticeWi}>
                    <span>
                      We do not receive any information from third parties.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.visitOurWebsite}>
                    <b>How do we process your information?</b>
                  </p>
                  <p className={styles.visitOurWebsite}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.readingThisPrivacyNoticeWi}>
                    <span>
                      We process your information to provide, improve, and
                      administer our Services, communicate with you, for
                      security and fraud prevention, and to comply with law. We
                      may also process your information for other purposes with
                      your consent. We process your information only when we
                      have a valid legal reason to do so.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.visitOurWebsite}>
                    <b>
                      In what situations and with which parties do we share
                      personal information?
                    </b>
                  </p>
                  <p className={styles.visitOurWebsite}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.readingThisPrivacyNoticeWi}>
                    <span>
                      We may share information in specific situations and with
                      specific third parties.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.visitOurWebsite}>
                    <b>How do we keep your information safe?</b>
                  </p>
                  <p className={styles.visitOurWebsite}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.readingThisPrivacyNoticeWi}>
                    <span>
                      We have organizational and technical processes and
                      procedures in place to protect your personal information.
                      However, no electronic transmission over the internet or
                      information storage technology can be guaranteed to be
                      100% secure, so we cannot promise or guarantee that
                      hackers, cybercriminals, or other unauthorized third
                      parties will not be able to defeat our security and
                      improperly collect, access, steal, or modify your
                      information.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.visitOurWebsite}>
                    <b>What are your rights?</b>
                  </p>
                  <p className={styles.visitOurWebsite}>
                    <b>&nbsp;</b>
                  </p>
                  <p className={styles.readingThisPrivacyNoticeWi}>
                    <span>
                      Depending on where you are located geographically, the
                      applicable privacy law may mean you have certain rights
                      regarding your personal information.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.visitOurWebsite}>
                    <b>How do you exercise your rights?</b>
                  </p>
                  <p className={styles.visitOurWebsite}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.readingThisPrivacyNoticeWi}>
                    <span>
                      The easiest way to exercise your rights is by filling out
                      our data subject request form, or by contacting us. We
                      will consider and act upon any request in accordance with
                      applicable data protection laws.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>
                1. WHAT INFORMATION DO WE COLLECT?
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.visitOurWebsite}>
                    <b>Personal information you disclose to us</b>
                  </p>
                  <p className={styles.visitOurWebsite}>
                    <b>&nbsp;</b>
                  </p>
                  <p className={styles.readingThisPrivacyNoticeWi}>
                    <b>{`In Short: `}</b>
                    <span>
                      We collect personal information that you provide to us.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody12}>
              <div className={styles.p14}>
                <div className={styles.caption}>
                  We collect personal information that you voluntarily provide
                  to us when you register on the Services, express an interest
                  in obtaining information about us or our products and
                  Services, when you participate in activities on the Services,
                  or otherwise when you contact us.
                </div>
              </div>
              <div className={styles.textbody13}>
                <div className={styles.p}>
                  <div className={styles.caption}>
                    <b>{`Personal Information Provided by You. `}</b>
                    <span>
                      The personal information that we collect depends on the
                      context of your interactions with us and the Services, the
                      choices you make, and the products and features you use.
                      The personal information we collect may include the
                      following:
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.p16}>
                <b className={styles.caption}>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>names</li>
                    <li className={styles.firstAndLast}>phone numbers</li>
                    <li className={styles.firstAndLast}>email addresses</li>
                    <li className={styles.firstAndLast}>mailing addresses</li>
                    <li className={styles.firstAndLast}>billing addresses</li>
                    <li className={styles.firstAndLast}>
                      contact or authentication data
                    </li>
                    <li className={styles.firstAndLast}>contact preferences</li>
                    <li>debit/credit card numbers</li>
                  </ul>
                </b>
              </div>
              <div className={styles.textbody14}>
                <div className={styles.p}>
                  <div className={styles.caption}>
                    <p className={styles.visitOurWebsite}>
                      <span>
                        <b>Sensitive Information</b>. When necessary, with your consent
                        or as otherwise permitted by applicable law, we process
                        the following categories of sensitive information:
                      </span>
                    </p>
                    <p className={styles.visitOurWebsite}>
                      <b>&nbsp;</b>
                    </p>
                    <ul className={styles.engageWithUsInOtherRelate}>
                      <li>
                        <b>health data</b>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className={styles.textbody13}>
                <div className={styles.p}>
                  <div className={styles.caption}>
                    <p className={styles.visitOurWebsite}>
                      <b className={styles.paymentData}>Payment Data.</b>
                      <span>
                        {" "}
                        We may collect data necessary to process your payment if
                        you make purchases, such as your payment instrument
                        number, and the security code associated with your
                        payment instrument.
                      </span>
                    </p>
                    <p className={styles.visitOurWebsite}>
                      <span>&nbsp;</span>
                    </p>
                    <p className={styles.readingThisPrivacyNoticeWi}>
                      <span>
                        All personal information that you provide to us must be
                        true, complete, and accurate, and you must notify us of
                        any changes to such personal information.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.visitOurWebsite}>
                    <b>Information automatically collected</b>
                  </p>
                  <p className={styles.visitOurWebsite}>
                    <b>&nbsp;</b>
                  </p>
                  <p className={styles.readingThisPrivacyNoticeWi}>
                    <span>
                      In Short: Some information — such as your Internet
                      Protocol (IP) address and/or browser and device
                      characteristics — is collected automatically when you
                      visit our Services.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.p14}>
              <div className={styles.caption}>
                We automatically collect certain information when you visit,
                use, or navigate the Services. This information does not reveal
                your specific identity (like your name or contact information)
                but may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about how and when you use our Services,
                and other technical information. This information is primarily
                needed to maintain the security and operation of our Services,
                and for our internal analytics and reporting purposes.
              </div>
            </div>
            <div className={styles.p14}>
              <div className={styles.caption}>
                The information we collect includes:
              </div>
            </div>
            <div className={styles.p16}>
              <div className={styles.caption}>
                <ul className={styles.engageWithUsInOtherRelate}>
                  <li className={styles.firstAndLast}>
                    Log and Usage Data. Log and usage data is service-related,
                    diagnostic, usage, and performance information our servers
                    automatically collect when you access or use our Services
                    and which we record in log files. Depending on how you
                    interact with us, this log data may include your IP address,
                    device information, browser type, and settings and
                    information about your activity in the Services (such as the
                    date/time stamps associated with your usage, pages and files
                    viewed, searches, and other actions you take such as which
                    features you use), device event information (such as system
                    activity, error reports (sometimes called "crash dumps"),
                    and hardware settings).
                  </li>
                </ul>
                <p className={styles.visitOurWebsite}>&nbsp;</p>
                <ul className={styles.engageWithUsInOtherRelate}>
                  <li className={styles.firstAndLast}>
                    Device Data. We collect device data such as information
                    about your computer, phone, tablet, or other device you use
                    to access the Services. Depending on the device used, this
                    device data may include information such as your IP address
                    (or proxy server), device and application identification
                    numbers, location, browser type, hardware model, Internet
                    service provider and/or mobile carrier, operating system,
                    and system configuration information.
                  </li>
                </ul>
                <p className={styles.visitOurWebsite}>&nbsp;</p>
                <ul className={styles.engageWithUsInOtherRelate}>
                  <li className={styles.firstAndLast}>
                    Location Data. We collect location data such as information
                    about your device's location, which can be either precise or
                    imprecise. How much information we collect depends on the
                    type and settings of the device you use to access the
                    Services. For example, we may use GPS and other technologies
                    to collect geolocation data that tells us your current
                    location (based on your IP address). You can opt out of
                    allowing us to collect this information either by refusing
                    access to the information or by disabling your Location
                    setting on your device. However, if you choose to opt out,
                    you may not be able to use certain aspects of the Services.
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>
                2. HOW DO WE PROCESS YOUR INFORMATION?
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <b>{`In Short: `}</b>
                  <span>
                    We process your information to provide, improve, and
                    administer our Services, communicate with you, for security
                    and fraud prevention, and to comply with law. We may also
                    process your information for other purposes with your
                    consent.
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <b className={styles.caption}>
                  We process your personal information for a variety of reasons,
                  depending on how you interact with our Services, including:
                </b>
              </div>
            </div>
            <div className={styles.textbody19}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>
                      <b
                        className={styles.paymentData}
                      >{`To facilitate account creation and authentication and otherwise manage user accounts. `}</b>
                      <span>
                        We may process your information so you can create and
                        log in to your account, as well as keep your account in
                        working order.
                      </span>
                    </li>
                  </ul>
                  <p className={styles.visitOurWebsite}>
                    <b>&nbsp;</b>
                  </p>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>
                      <b>{`To deliver and facilitate delivery of services to the user. `}</b>
                      <span>
                        We may process your information to provide you with the
                        requested service.
                      </span>
                    </li>
                  </ul>
                  <p className={styles.visitOurWebsite}>
                    <b>&nbsp;</b>
                  </p>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>
                      <b>{`To respond to user inquiries/offer support to users. `}</b>
                      <span>
                        We may process your information to respond to your
                        inquiries and solve any potential issues you might have
                        with the requested service.
                      </span>
                    </li>
                  </ul>
                  <p className={styles.visitOurWebsite}>
                    <b>&nbsp;</b>
                  </p>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>
                      <b>{`To send administrative information to you. `}</b>
                      <span>
                        We may process your information to send you details
                        about our products and services, changes to our terms
                        and policies, and other similar information.
                      </span>
                    </li>
                  </ul>
                  <p className={styles.visitOurWebsite}>
                    <b>&nbsp;</b>
                  </p>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>
                      <b>{`To fulfill and manage your orders. `}</b>
                      <span>
                        We may process your information to fulfill and manage
                        your orders, payments, returns, and exchanges made
                        through the Services.
                      </span>
                    </li>
                  </ul>
                  <p className={styles.visitOurWebsite}>
                    <b>&nbsp;</b>
                  </p>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>
                      <b>{`To request feedback. `}</b>
                      <span>
                        We may process your information when necessary to
                        request feedback and to contact you about your use of
                        our Services.
                      </span>
                    </li>
                  </ul>
                  <p className={styles.visitOurWebsite}>
                    <b>&nbsp;</b>
                  </p>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>
                      <b>{`To send you marketing and promotional communications. `}</b>
                      <span>
                        We may process the personal information you send to us
                        for our marketing purposes, if this is in accordance
                        with your marketing preferences.
                      </span>
                    </li>
                  </ul>
                  <p className={styles.visitOurWebsite}>
                    <b>&nbsp;</b>
                  </p>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>
                      <b>{`To deliver targeted advertising to you. `}</b>
                      <span>
                        We may process your information to develop and display
                        personalized content and advertising tailored to your
                        interests, location, and more.
                      </span>
                    </li>
                  </ul>
                  <p className={styles.visitOurWebsite}>
                    <b>&nbsp;</b>
                  </p>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>
                      <b>{`To post testimonials. `}</b>
                      <span>
                        We post testimonials on our Services that may contain
                        personal information.
                      </span>
                    </li>
                  </ul>
                  <p className={styles.visitOurWebsite}>
                    <b>&nbsp;</b>
                  </p>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>
                      <b>{`To protect our Services. `}</b>
                      <span>
                        We may process your information as part of our efforts
                        to keep our Services safe and secure, including fraud
                        monitoring and prevention.
                      </span>
                    </li>
                  </ul>
                  <p className={styles.visitOurWebsite}>
                    <b>&nbsp;</b>
                  </p>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>
                      <b>{`To evaluate and improve our Services, products, marketing, and your experience. `}</b>
                      <span>
                        We may process your information when we believe it is
                        necessary to identify usage trends, determine the
                        effectiveness of our promotional campaigns, and to
                        evaluate and improve our Services, products, marketing,
                        and your experience.
                      </span>
                    </li>
                  </ul>
                  <p className={styles.visitOurWebsite}>
                    <b>&nbsp;</b>
                  </p>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li>
                      <b>{`To comply with our legal obligations. `}</b>
                      <span>
                        We may process your information to comply with our legal
                        obligations, respond to legal requests, and exercise,
                        establish, or defend our legal rights.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>
                3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <b>{`In Short: `}</b>
                  <span>
                    We only process your personal information when we believe it
                    is necessary and we have a valid legal reason (i.e., legal
                    basis) to do so under applicable law, like with your
                    consent, to comply with laws, to provide you with services
                    to enter into or fulfill our contractual obligations, to
                    protect your rights, or to fulfill our legitimate business
                    interests.
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <b className={styles.caption27}>
                  If you are located in Canada, this section applies to you.
                </b>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.visitOurWebsite}>
                    We may process your information if you have given us
                    specific permission (i.e., express consent) to use your
                    personal information for a specific purpose, or in
                    situations where your permission can be inferred (i.e.,
                    implied consent).
                  </p>
                  <p className={styles.visitOurWebsite}>&nbsp;</p>
                  <p className={styles.readingThisPrivacyNoticeWi}>
                    In some exceptional cases, we may be legally permitted under
                    applicable law to process your information without your
                    consent, including, for example:
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody23}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>
                      If collection is clearly in the interests of an individual
                      and consent cannot be obtained in a timely way
                    </li>
                  </ul>
                  <p className={styles.visitOurWebsite}>&nbsp;</p>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>
                      For investigations and fraud detection and prevention
                    </li>
                  </ul>
                  <p className={styles.visitOurWebsite}>&nbsp;</p>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>
                      For business transactions provided certain conditions are
                      met
                    </li>
                  </ul>
                  <p className={styles.visitOurWebsite}>&nbsp;</p>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>
                      If it is contained in a witness statement and the
                      collection is necessary to assess, process, or settle an
                      insurance claim
                    </li>
                  </ul>
                  <p className={styles.visitOurWebsite}>&nbsp;</p>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>
                      For identifying injured, ill, or deceased persons and
                      communicating with next of kin
                    </li>
                  </ul>
                  <p className={styles.visitOurWebsite}>&nbsp;</p>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>
                      If we have reasonable grounds to believe an individual has
                      been, is, or may be victim of financial abuse
                    </li>
                  </ul>
                  <p className={styles.visitOurWebsite}>&nbsp;</p>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>
                      If it is reasonable to expect collection and use with
                      consent would compromise the availability or the accuracy
                      of the information and the collection is reasonable for
                      purposes related to investigating a breach of an agreement
                      or a contravention of the laws of Canada or a province
                    </li>
                  </ul>
                  <p className={styles.visitOurWebsite}>&nbsp;</p>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>
                      If disclosure is required to comply with a subpoena,
                      warrant, court order, or rules of the court relating to
                      the production of records
                    </li>
                  </ul>
                  <p className={styles.visitOurWebsite}>&nbsp;</p>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>
                      If it was produced by an individual in the course of their
                      employment, business, or profession and the collection is
                      consistent with the purposes for which the information was
                      produced
                    </li>
                  </ul>
                  <p className={styles.visitOurWebsite}>&nbsp;</p>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li className={styles.firstAndLast}>
                      If the collection is solely for journalistic, artistic, or
                      literary purposes
                    </li>
                  </ul>
                  <p className={styles.visitOurWebsite}>&nbsp;</p>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li>
                      If the information is publicly available and is specified
                      by the regulations
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>
                4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <b>{`In Short: `}</b>
                  <span>
                    We may share information in specific situations described in
                    this section and/or with the following third parties.
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p14}>
                <div className={styles.caption}>
                  We may need to share your personal information in the
                  following situations:
                </div>
              </div>
              <div className={styles.p32}>
                <div className={styles.caption}>
                  <ul className={styles.engageWithUsInOtherRelate}>
                    <li>
                    <b
                      className={styles.paymentData}
                    >{`Business Transfers. `}</b>
                    <span>
                      We may share or transfer your information in connection
                      with, or during negotiations of, any merger, sale of
                      company assets, financing, or acquisition of all or a
                      portion of our business to another company.
                    </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>
                5. HOW LONG DO WE KEEP YOUR INFORMATION?
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <b>{`In Short: `}</b>
                  <span>
                    We keep your information for as long as necessary to fulfill
                    the purposes outlined in this privacy notice unless
                    otherwise required by law.
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.visitOurWebsite}>
                    We will only keep your personal information for as long as
                    it is necessary for the purposes set out in this privacy
                    notice, unless a longer retention period is required or
                    permitted by law (such as tax, accounting, or other legal
                    requirements). No purpose in this notice will require us to
                    keep your personal information for longer than the period of
                    time in which users have an account with us.
                  </p>
                  <p className={styles.visitOurWebsite}>&nbsp;</p>
                  <p className={styles.readingThisPrivacyNoticeWi}>
                    When we have no ongoing legitimate business need to process
                    your personal information, we will either delete or
                    anonymize such information, or, if this is not possible (for
                    example, because your personal information has been stored
                    in backup archives), then we will securely store your
                    personal information and isolate it from any further
                    processing until deletion is possible.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>
                6. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <b>{`In Short: `}</b>
                  <span>
                    We aim to protect your personal information through a system
                    of organizational and technical security measures.
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p14}>
                <div className={styles.caption}>
                  We have implemented appropriate and reasonable technical and
                  organizational security measures designed to protect the
                  security of any personal information we process. However,
                  despite our safeguards and efforts to secure your information,
                  no electronic transmission over the Internet or information
                  storage technology can be guaranteed to be 100% secure, so we
                  cannot promise or guarantee that hackers, cybercriminals, or
                  other unauthorized third parties will not be able to defeat
                  our security and improperly collect, access, steal, or modify
                  your information. Although we will do our best to protect your
                  personal information, transmission of personal information to
                  and from our Services is at your own risk. You should only
                  access the Services within a secure environment.
                </div>
              </div>
              <div className={styles.p32}>
                <div className={styles.caption}>
                  {/* <ul className={styles.engageWithUsInOtherRelate}>
                    <b
                      className={styles.paymentData}
                    >{`Business Transfers. `}</b>
                    <span>
                      We may share or transfer your information in connection
                      with, or during negotiations of, any merger, sale of
                      company assets, financing, or acquisition of all or a
                      portion of our business to another company.
                    </span>
                  </ul> */}
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>
                7. DO WE COLLECT INFORMATION FROM MINORS?
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <b>{`In Short: `}</b>
                  <span>
                    We do not knowingly collect data from or market to children
                    under 18 years of age.
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  We do not knowingly solicit data from or market to children
                  under 18 years of age. By using the Services, you represent
                  that you are at least 18 or that you are the parent or
                  guardian of such a minor and consent to such minor dependent’s
                  use of the Services. If we learn that personal information
                  from users less than 18 years of age has been collected, we
                  will deactivate the account and take reasonable measures to
                  promptly delete such data from our records. If you become
                  aware of any data we may have collected from children under
                  age 18, please contact us at <Link href="tel:18885741440" color="#267F88">+1-888-574-1440</Link>.
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>
                8. WHAT ARE YOUR PRIVACY RIGHTS?
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <b>{`In Short: `}</b>
                  <span>
                    In some regions, such as Canada, you have rights that allow
                    you greater access to and control over your personal
                    information. You may review, change, or terminate your
                    account at any time.
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  In some regions (like Canada), you have certain rights under
                  applicable data protection laws. These may include the right
                  (i) to request access and obtain a copy of your personal
                  information, (ii) to request rectification or erasure; (iii)
                  to restrict the processing of your personal information; and
                  (iv) if applicable, to data portability. In certain
                  circumstances, you may also have the right to object to the
                  processing of your personal information.
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  We will consider and act upon any request in accordance with
                  applicable data protection laws.
                </div>
              </div>
            </div>
            <div className={styles.textbody35}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  {`If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: `}
                  <a
                    className={styles.httpseceuropaeujusticed}
                    href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                    target="_blank"
                    color="#267F88"
                  >
                    <span className={styles.httpseceuropaeujusticed1} color="#267F88">
                      https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                    </span>
                  </a>
                  .
                </div>
              </div>
            </div>
            <div className={styles.textbody35}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.visitOurWebsite}>
                    {`If you are located in Switzerland, the contact details for the data protection authorities are available here: `}
                    <a
                      className={styles.httpseceuropaeujusticed}
                      href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                      target="_blank"
                    >
                      <span className={styles.httpseceuropaeujusticed1}>
                        https://www.edoeb.admin.ch/edoeb/en/home.html
                      </span>
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <b>Withdrawing your consent:</b>
                  <span>
                    {" "}
                    If we are relying on your consent to process your personal
                    information, which may be express and/or implied consent
                    depending on the applicable law, you have the right to
                    withdraw your consent at any time.
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.visitOurWebsite}>
                    However, please note that this will not affect the
                    lawfulness of the processing before its withdrawal nor, when
                    applicable law allows, will it affect the processing of your
                    personal information conducted in reliance on lawful
                    processing grounds other than consent.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <b className={styles.caption}>Account Information</b>
              </div>
            </div>
            <div className={styles.textbody38}>
              <div className={styles.p14}>
                <div className={styles.caption}>
                  <p className={styles.visitOurWebsite}>
                    If you would at any time like to review or change the
                    information in your account or terminate your account, you
                    can:
                  </p>
                  <p className={styles.readingThisPrivacyNoticeWi}>
                    Upon your request to terminate your account, we will
                    deactivate or delete your account and information from our
                    active databases. However, we may retain some information in
                    our files to prevent fraud, troubleshoot problems, assist
                    with any investigations, enforce our legal terms and/or
                    comply with applicable legal requirements.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>
                9. CONTROLS FOR DO-NOT-TRACK FEATURES
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  Most web browsers and some mobile operating systems and mobile
                  applications include a Do-Not-Track ("DNT") feature or setting
                  you can activate to signal your privacy preference not to have
                  data about your online browsing activities monitored and
                  collected. At this stage no uniform technology standard for
                  recognizing and implementing DNT signals has been finalized.
                  As such, we do not currently respond to DNT browser signals or
                  any other mechanism that automatically communicates your
                  choice not to be tracked online. If a standard for online
                  tracking is adopted that we must follow in the future, we will
                  inform you about that practice in a revised version of this
                  privacy notice.
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>
                10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <b>{`In Short: `}</b>
                  <span>
                    Yes, if you are a resident of California, you are granted
                    specific rights regarding access to your personal
                    information.
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  California Civil Code Section 1798.83, also known as the
                  "Shine The Light" law, permits our users who are California
                  residents to request and obtain from us, once a year and free
                  of charge, information about categories of personal
                  information (if any) we disclosed to third parties for direct
                  marketing purposes and the names and addresses of all third
                  parties with which we shared personal information in the
                  immediately preceding calendar year. If you are a California
                  resident and would like to make such a request, please submit
                  your request in writing to us using the contact information
                  provided below.
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  If you are under 18 years of age, reside in California, and
                  have a registered account with Services, you have the right to
                  request removal of unwanted data that you publicly post on the
                  Services. To request removal of such data, please contact us
                  using the contact information provided below and include the
                  email address associated with your account and a statement
                  that you reside in California. We will make sure the data is
                  not publicly displayed on the Services, but please be aware
                  that the data may not be completely or comprehensively removed
                  from all our systems (e.g., backups, etc.).
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>
                11. DO WE MAKE UPDATES TO THIS NOTICE?
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <b>{`In Short: `}</b>
                  <span>
                    Yes, we will update this notice as necessary to stay
                    compliant with relevant laws.
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  We may update this privacy notice from time to time. The
                  updated version will be indicated by an updated "Revised" date
                  and the updated version will be effective as soon as it is
                  accessible. If we make material changes to this privacy
                  notice, we may notify you either by prominently posting a
                  notice of such changes or by directly sending you a
                  notification. We encourage you to review this privacy notice
                  frequently to be informed of how we are protecting your
                  information.
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>
                12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  If you have questions or comments about this notice, you may
                  email us at <Link href="mailto:info@tcpsupply.net" color="#267F88">info@tcpsupply.net</Link>
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>
                13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  Based on the applicable laws of your country, you may have the
                  right to request access to the personal information we collect
                  from you, change that information, or delete it. To request to
                  review, update, or delete your personal information, please
                  submit a request form by clicking <AnchorLink to="/#contactSectionContainer"><Link  color="#267F88">here</Link></AnchorLink>.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Layout>
    </>
  );
};

export default PrivacyPolicy;
