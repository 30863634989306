// extracted by mini-css-extract-plugin
export var bannerSuper = "privacy-policy-module--bannerSuper--DiFLa";
export var blankLine = "privacy-policy-module--blankLine--2ZUQU";
export var blankLine1 = "privacy-policy-module--blankLine1--09lOz";
export var button = "privacy-policy-module--button--xlHJx";
export var canadianSupply = "privacy-policy-module--canadianSupply--3FE3M";
export var caption = "privacy-policy-module--caption--msCzb";
export var caption27 = "privacy-policy-module--caption27--RyeYS";
export var ccs = "privacy-policy-module--ccs--ZP7rs";
export var column = "privacy-policy-module--column--tajR2";
export var display = "privacy-policy-module--display--2AeDR";
export var display1 = "privacy-policy-module--display1--vD8yG";
export var docTemplate = "privacy-policy-module--docTemplate--ASDET";
export var email = "privacy-policy-module--email--hStFA";
export var engageWithUsInOtherRelate = "privacy-policy-module--engageWithUsInOtherRelate--3tzjd";
export var firstAndLast = "privacy-policy-module--firstAndLast--eJRui";
export var footer = "privacy-policy-module--footer--M+871";
export var footerContent = "privacy-policy-module--footerContent--9kuNQ";
export var footerL = "privacy-policy-module--footerL--kI9rJ";
export var footerLinks = "privacy-policy-module--footerLinks--Bv700";
export var footerR = "privacy-policy-module--footerR--VsHDi";
export var fullNavBar = "privacy-policy-module--fullNavBar--DJrOh";
export var httpseceuropaeujusticed = "privacy-policy-module--httpseceuropaeujusticed--sqeyX";
export var httpseceuropaeujusticed1 = "privacy-policy-module--httpseceuropaeujusticed1--MuE6B";
export var iconButton = "privacy-policy-module--iconButton--uYzBM";
export var iconButton1 = "privacy-policy-module--iconButton1--NJH+v";
export var iconFilled = "privacy-policy-module--iconFilled--7PUpw";
export var infowebsitecom = "privacy-policy-module--infowebsitecom--JUWLN";
export var logo = "privacy-policy-module--logo--ljlq1";
export var logo1 = "privacy-policy-module--logo1--Wz7bB";
export var navbarContent = "privacy-policy-module--navbarContent--D1sbK";
export var navbarContentMobile = "privacy-policy-module--navbarContentMobile--aU3HY";
export var navigation = "privacy-policy-module--navigation--Bfb1V";
export var navigation1 = "privacy-policy-module--navigation1--K6am9";
export var p = "privacy-policy-module--p--xVg34";
export var p1 = "privacy-policy-module--p1--kmci7";
export var p14 = "privacy-policy-module--p14--Ag7c4";
export var p16 = "privacy-policy-module--p16--uuytN";
export var p32 = "privacy-policy-module--p32--RbA5H";
export var paymentData = "privacy-policy-module--paymentData--LsKRF";
export var privacyPolicy = "privacy-policy-module--privacyPolicy--7d6oQ";
export var readingThisPrivacyNoticeWi = "privacy-policy-module--readingThisPrivacyNoticeWi--Ol07A";
export var shippingPolicy = "privacy-policy-module--shippingPolicy--ImhZv";
export var subtitle = "privacy-policy-module--subtitle--2KeGg";
export var subtitleLarge = "privacy-policy-module--subtitleLarge--CHws9";
export var tcpSupplyLogoIcon = "privacy-policy-module--tcpSupplyLogoIcon--aYBQ2";
export var tcpSupplyLogoIcon2 = "privacy-policy-module--tcpSupplyLogoIcon2--G3PO7";
export var textColumn = "privacy-policy-module--textColumn--xYC4V";
export var textbody = "privacy-policy-module--textbody--gW9q-";
export var textbody12 = "privacy-policy-module--textbody12--gK0Ng";
export var textbody13 = "privacy-policy-module--textbody13--+18K6";
export var textbody14 = "privacy-policy-module--textbody14--IQ7Iv";
export var textbody19 = "privacy-policy-module--textbody19--1J3aT";
export var textbody2 = "privacy-policy-module--textbody2--7r-bR";
export var textbody23 = "privacy-policy-module--textbody23--d9E7H";
export var textbody35 = "privacy-policy-module--textbody35--sNZzW";
export var textbody38 = "privacy-policy-module--textbody38--i4-KO";
export var visaIcon = "privacy-policy-module--visaIcon--vpZLG";
export var visitOurWebsite = "privacy-policy-module--visitOurWebsite--aDnW7";